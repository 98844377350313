export function reducer(state, action) {
  switch (action.type) {
    case "isMobile":
      return {
        ...state,
        isMobile: action.payload,
      };
    case "language":
      return {
        ...state,
        language: action.payload,
      };
    case "availableVideos":
      return {
        ...state,
        availableVideos: action.payload,
      };
    case "localHistory":
      return {
        ...state,
        localHistory: action.payload,
      };
    case "onlyContent":
      return {
        ...state,
        onlyContent: action.payload,
      };
    case "userInfo":
      return {
        ...state,
        userInfo: action.payload,
      };
    case "savedList":
      return {
        ...state,
        savedList: action.payload,
      };
    case "account":
      return {
        ...state,
        account: action.payload,
      };
    case "videoList":
      return {
        ...state,
        videoList: action.payload,
      };
    case "seriesList":
      return {
        ...state,
        seriesList: action.payload,
      };
    case "currentLoginAccount":
      return {
        ...state,
        currentLoginAccount: action.payload,
      };
    case "currentLoginID":
      return {
        ...state,
        currentLoginID: action.payload,
      };
    case "currentLoginPermission":
      return {
        ...state,
        currentLoginPermission: action.payload,
      };
    case "currentPurchased":
      return {
        ...state,
        currentPurchased: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
