import React, { useReducer, createContext } from "react";
import { reducer } from "./reducer";

const initialState = {
  isMobile: false,
  language: "EN",
  currentLoginAccount: "",
  availableVideos: [],
  localHistory: {},
  onlyContent: false,
  userInfo: false,
  savedList: [],
};

// Initialize context with default value
const Context = createContext({
  state: initialState,
  dispatch: () => {},
});

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { Context, Provider };
