import React, { useState, useRef } from "react";
import "../dashboard/dashboard.css";

export default function AccountPage({
  name,
  uid,
  coin,
  mouseLeave,
  loginOpen,
}) {
  const loginPageRef = useRef(null);
  const signIn = () => {
    loginOpen(true);
  };

  const handleCloseAccount = () => {
    mouseLeave();
  };

  const signOut = () => {
    // const localGuest = JSON.parse(localStorage.getItem("localGuest"));
    // localStorage.setItem("userInfo", JSON.stringify(localGuest));
    // localStorage.removeItem("localGuest");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("docId");
    localStorage.removeItem("loginId");

    window.location.reload();
  };

  const handleButtonClick = name === "Guest" ? signIn : signOut;

  return (
    <div>
      {
        <div className="accountPage" onMouseLeave={handleCloseAccount}>
          <div className="accountPage-top">
            <div className="accountTitle-signin-module">
              <img
                className="icon-selfie"
                src={process.env.PUBLIC_URL + `/ads/dashboard.png`}
                alt="Logo"
              />
              <span className="accountTitle-signin">
                <div className="text-acc-name"> {name} </div>
                <div className="text-acc-uid"> UID {uid}</div>
              </span>
            </div>
            <button
              className="accountButton-signin"
              onClick={handleButtonClick}
            >
              {name === "Guest" ? "Sign in" : "Sign out"}
            </button>
          </div>
          <div className="accountPage-bottom">
            <span className="accountTitle-coins">
              <img
                className="icon-coin"
                src={process.env.PUBLIC_URL + `/ui/Coin.png`}
                alt="Coin"
              />{" "}
              <span className="text-coin-num">{coin}</span>{" "}
              <span className="text-coin-name">Coins</span>
            </span>
            <a className="accountButton-payment" href={`/dashboard?tab=shop`}>
              Top Up
            </a>
          </div>
        </div>
      }
    </div>
  );
}
