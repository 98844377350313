import "./App.css";
import PublicLayout from "./component/layout/publicLayout";
import { BrowserRouter as Router } from "react-router-dom";
import React, { useEffect, useContext } from "react";
import CustomRoute from "./Route";
import { Context } from "./context/index";
import {
  collection,
  where,
  query,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "./server/firebase";
import { GetUserData } from "./accounts";
import { moveFieldsToSubcollection } from "./context/untility";

export default function App() {
  const { state, dispatch } = useContext(Context);
  const { availableVideos, userInfo } = state;

  const updateEpisodesOrderAsync = async () => {
    const videosRef = collection(db, "videos");
    const snapshot = await getDocs(videosRef);

    snapshot.forEach(async (docSnapshot) => {
      const videoData = docSnapshot.data();

      if (videoData.episodes && videoData.episodes.length > 1) {
        // 对episodes数组进行排序
        const sortedEpisodes = videoData.episodes.sort(
          (a, b) => a.episode_number - b.episode_number
        );

        // 更新排序后的episodes数组回Firebase
        const videoRef = doc(db, "videos", docSnapshot.id);
        await updateDoc(videoRef, {
          episodes: sortedEpisodes,
        });
      }
    });
  };

  useEffect(() => {
    const userDataPre = localStorage.getItem("userInfo");

    async function fetchData() {
      // await GetUserData();
      //自动顺毛程式，处理上传人员乱传剧集顺序的情况
      updateEpisodesOrderAsync().catch(console.error);

      const userData = localStorage.getItem("userInfo");
      if (userData) {
        // console.log(`setInfo to ${userData}`);
        dispatch({
          type: "userInfo",
          payload: JSON.parse(userData),
        });
        dispatch({
          type: "savedList",
          payload: JSON.parse(userData.list ? userData.list : "[]"),
        });
      }
    }
    async function fetchVideoData() {
      const ref = collection(db, "videos");
      const videosSnapshot = await getDocs(ref);
      let avList = videosSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dispatch({
        type: "availableVideos",
        payload: avList,
      });

      // moveFieldsToSubcollection();
    }
    fetchVideoData();
    fetchData();

    // console.log(`userDataPre: ${userDataPre}`);
    // if (userDataPre === undefined || userDataPre === null) {
    //   fetchData();
    // } else {
    //   fetchVideoData();
    //   const userData = localStorage.getItem("userInfo");
    //   console.log(`setInfo to ${userData}`);
    //   dispatch({
    //     type: "userInfo",
    //     payload: JSON.parse(userData),
    //   });
    // }
  }, []);

  return (
    <Router>
      {/* <div className="App"> */}
      <PublicLayout>
        <CustomRoute />
      </PublicLayout>
      {/* </div> */}
    </Router>
  );
}
