// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
import { getFirestore } from 'firebase/firestore';
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAiLP4RWdaTFCs1-YcF8ajsn40TD6EXzMM",
  authDomain: "duanju-template.firebaseapp.com",
  projectId: "duanju-template",
  storageBucket: "duanju-template.appspot.com",
  messagingSenderId: "277900231791",
  appId: "1:277900231791:web:b60681378f3c621453399a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);