import { lazy } from "react";

export const Path = [
  {
    path: "/",
    component: lazy(() => import("./pages/main")),
  },

  {
    path: "/text",
    component: lazy(() => import("./pages/text/text")),
  },
  {
    path: "/play",
    component: lazy(() => import("./pages/video/video")),
  },
  {
    path: "/dashboard",
    component: lazy(() => import("./pages/dashboard/dashboard")),
  },
  {
    path: "/admin",
    component: lazy(() => import("./pages/account/adminPage")),
  },
  {
    path: "/login",
    component: lazy(() => import("./pages/account/loginPage")),
  },
  // {
  //   path: "/dashboard/shop",
  //   component: lazy(() => import("./pages/dashboard/dashboard")),
  // },
  {
    path: "/profile",
    component: lazy(() => import("./pages/profile/dashboard")),
  },
  {
    path: "*",
    component: lazy(() => import("./pages/error")),
  },
  {
    path: "/upload",
    component: lazy(() => import("./pages/Admin/uploadVideo")),
  }
];
