import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import Layout from "./component/Layout/publicLayout";
import { Provider as ContextProvider } from "./context/index";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ContextProvider>
      {/* <RouterProvider router={router} /> */}
      <App style={{ background: "#FFF" }} />
    </ContextProvider>
  </React.StrictMode>
);
