import "./footer.css";
import React, { Fragment } from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";

export default function CSFotter() {
  const { Footer } = Layout;
  return (
    <Footer class="main-footer">
      <div class="footer-container">
        <div className="footer-content">
          <div className="footer-community-container">
            <span className="footer-community-title">Community</span>
            <div className="footer-community-icons">
              <a href="https://www.instagram.com/reelfreeway/" target="_blank">
                <img
                  src={
                    process.env.PUBLIC_URL + `/ui/Icon akar-instagram-fill.png`
                  }
                />
              </a>
              <a href="https://www.youtube.com/@reelfreeway" target="_blank">
                <img
                  src={
                    process.env.PUBLIC_URL + `/ui/Icon akar-youtube-fill.png`
                  }
                />
              </a>
              <a href="https://www.tiktok.com/@reelfreeway" target="_blank">
                <img
                  src={process.env.PUBLIC_URL + `/ui/Icon akar-tiktok-fill.png`}
                />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61557205027050"
                target="_blank"
              >
                <img
                  src={
                    process.env.PUBLIC_URL + `/ui/Icon akar-facebook-fill.png`
                  }
                />
              </a>
            </div>
          </div>

          <div className="footer-download-container">
            <span className="footer-download-title">Download</span>

            <a target="_blank" href="https://shorturl.at/SWXZ9">
              <img
                style={{ height: "2.3vw" }}
                src={process.env.PUBLIC_URL + `/ui/AppleDownload.png`}
              />
            </a>
          </div>

          <div className="footer-supports-container">
            <span className="footer-supports-title">Support</span>
            <div className="footer-supports-icons">
              <a href="text?index=0">
                <span>Privacy</span>
              </a>
              <a href="text?index=1">
                <span>Customer Support</span>
              </a>
            </div>
          </div>
        </div>

        <span className="footer-copyright-container">
          &copy; 2023 Reel Freeway. All rights reserved.
        </span>
      </div>
    </Footer>
  );
}
