// import "../../App.css";
import "./header.css";
import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../../server/firebase";
import { Layout } from "antd";
import {
  doc,
  updateDoc,
  setDoc,
  getDoc,
  collection,
  where,
  query,
  getDocs,
} from "firebase/firestore";
import { Context } from "../../context/index.js";
import { GetUserData } from "../../accounts";
import AccountPage from "../../pages/account/accountPage";
import { useSearchParams } from "react-router-dom";
import LoginPage from "../loginPage";

export default function CSHeader() {
  const { state, dispatch } = useContext(Context);
  const { userInfo } = state;
  const { Header } = Layout;
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(false);
  const [login, setLogin] = useState(false);
  const [his, setHis] = useState(false);
  const isFirstLoaded = useRef(false);

  const [info, setInfo] = useState("");
  const [queryParameters] = useSearchParams();
  const code = queryParameters.get("code");
  const logoPath = "../logo128.png";
  const navigate = useNavigate();

  useEffect(() => {
    setInfo(userInfo);
  }, [userInfo]);

  const handleCloseLogin = () => {
    setLogin(false);
  };

  useEffect(() => {
    async function handleCode() {
      if (code) {
        try {
          // 发送 code 到后端端点获取令牌
          const response = await fetch("/exchange-apple-token", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ code }),
          });

          if (response.ok) {
            // 获取后端返回的 JSON 数据
            const data = await response.json();
            // 处理从后端获取的数据
            // console.log("Token data:", data);
          } else {
            console.error("Failed to exchange code for token");
          }
        } catch (error) {
          console.error("Error during fetch:", error);
        }
      }
    }

    handleCode();
  }, [code]);

  return (
    <>
      <Header class="header">
        <div class="header-logo">
          {/* <a href="/"> */}
          <div
            style={{
              width: "200px",
              height: "100px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + `/ads/RFlogo.png`}
              alt="Logo"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          {/* </a> */}
        </div>
        <div class="home">
          <div class="nav-link">
            <Link to="/">
              <span class="nav-item">
                <span class="nav-item-title">Home</span>
              </span>
            </Link>
          </div>
        </div>
        <div class="header-nav">
          {/* hiasdsadsdasda÷ */}
          <a target="_blank" href="https://shorturl.at/SWXZ9" class="nav-link">
            <div className="nav-item">
              <img
                src={process.env.PUBLIC_URL + `/ui/Download.png`}
                alt="Logo"
              />
              <span class="nav-item-subtitle">APP</span>
            </div>
          </a>
          {userInfo && (
            <div href="/dashboard?tab=my%20list" class="nav-link">
              <Link to="/dashboard">
                <div className="nav-item">
                  <img
                    src={process.env.PUBLIC_URL + `/ui/HistoryList.png`}
                    alt="Logo"
                  />
                  <span class="nav-item-subtitle">My List</span>
                </div>
              </Link>
            </div>
          )}

          <div style={{ position: "relative" }}>
            <div
              // href="/dashboard?tab=wallet"
              class="nav-dashboard"
              onMouseOver={function e() {
                if (userInfo) {
                  setAccount(true);
                }
              }}
              onClick={() => {
                if (!userInfo) {
                  navigate("/login");
                } else {
                  navigate("/profile");
                }
              }}
            >
              <img
                src={process.env.PUBLIC_URL + `/ads/dashboard.png`}
                alt="Logo"
              />
            </div>
            {account ? (
              <div id={info.uid}>
                <AccountPage
                  name={info.name}
                  uid={info.uid}
                  coin={info.coins}
                  mouseLeave={function e() {
                    setAccount(false);
                  }}
                  loginOpen={function e() {
                    setAccount(false);
                    setLogin(true);
                  }}
                />
              </div>
            ) : null}
          </div>
          {login && <LoginPage onClose={handleCloseLogin} />}
          {his ? <div className="hisPage"></div> : null}
        </div>
      </Header>
    </>
  );
}
