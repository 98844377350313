import {
  deleteDoc,
  onSnapshot,
  doc,
  updateDoc,
  setDoc,
  getDoc,
  collection,
  where,
  query,
  getDocs,
  orderBy,
  limit,
  count,
  addDoc,
} from "firebase/firestore";
import { db } from "./server/firebase";
import { v4 as uuidv4 } from "uuid";
// import { useContext } from "react";
// import { Context } from "./context";

export function CreateGuest() {
  const ref = collection(db, "users");

  const timestamp = new Date();

  let userData = {
    name: "Guest",
    uid: RandomId(),
    email: "",
    last_login: timestamp,
    create: timestamp,
    history: [],
    purchase: [],
    list: [],
    coins: 0,
    from: "web",
  };

  // addDoc(ref, userData);
  const snapT = JSON.stringify(userData);
  localStorage.setItem("userInfo", snapT);
  // console.log(`CreateGuest() created ${snapT}`);
  return snapT;
}

export function RandomId() {
  // 生成 UUID
  const uuid = uuidv4();

  // 移除横杠并返回前十位
  return uuid.replace(/-/g, "").substring(0, 10);
}

export async function GetUserData() {
  //   const { state, dispatch } = useContext(Context);
  // const { userInfo } = state;

  const userInfoString = localStorage.getItem("userInfo");

  const userInfo = JSON.parse(userInfoString);

  if (!userInfo) {
    const snapT = CreateGuest();
    console.log(`GetUserData() got ${snapT}\nFrom new created.`);
    return;
  }

  const ref = collection(db, "users");

  const q = query(ref, where("uid", "==", userInfo.uid));

  const snap = await getDocs(q);

  if (snap.empty) {
    const snapT = CreateGuest();
    // console.log(`GetUserData() got ${snapT}\nSnap is empty.`);
    return;
  } else {
    const userDoc = snap.docs[0]; // 获取第一个匹配文档的引用
    const docId = userDoc.id; // 获取文档 ID
    localStorage.setItem("docId", docId);

    snap.forEach((e) => {
      const { password: userPassword, ...userInfoWithoutPassword } = e.data();
      const snapT = JSON.stringify({
        email: userInfoWithoutPassword.email,
        uid: e.id,
        ...userInfoWithoutPassword,
      });
      localStorage.setItem("userInfo", snapT);
      // console.log(`GetUserData() got ${snapT}\nFrom snap.`);
      return JSON.parse(snapT);
    });
  }
}
