import React, { useState, useRef } from "react";
import firebase from "firebase/app";
import {
  deleteDoc,
  onSnapshot,
  doc,
  updateDoc,
  setDoc,
  getDoc,
  collection,
  where,
  query,
  getDocs,
  orderBy,
  limit,
  count,
  addDoc,
} from "firebase/firestore";
import { db } from "../server/firebase";
import { Button } from "antd";
import { v4 as uuidv4 } from "uuid";
// 确保 Firebase 已被初始化

const LoginPage = ({ onClose }) => {
  const [loginState, setLoginState] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const elementRef = useRef(null);

  function RandomId() {
    // 生成 UUID
    const uuid = uuidv4();

    // 移除横杠并返回前十位
    return uuid.replace(/-/g, "").substring(0, 10);
  }

  const triggerShake = () => {
    const element = elementRef.current;
    element.classList.add("shake-animation");
    element.addEventListener(
      "animationend",
      () => {
        element.classList.remove("shake-animation");
      },
      { once: true }
    );
  };

  const handleregister = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{10,}$/;
    if (!emailRegex.test(email)) {
      setErrorMsg("Invalid email format");
      triggerShake();
      return;
    }

    if (!passwordRegex.test(password)) {
      setErrorMsg(
        "Password must contain letters and numbers and be longer than 10 characters"
      );
      triggerShake();
      return;
    }

    if (isRegistering) {
      if (password !== confirmPassword) {
        setErrorMsg("Passwords do not match");
        triggerShake();
        return;
      }
    }
    let newUserInfo = {
      name: email.split("@")[0],
      uid: RandomId(),
      email: email,
      password: password,
      last_login: new Date(),
      create: new Date(),
      history: [],
      purchase: [],
      list: [],
      coins: 0,
      from: "web",
    };
    const ref = collection(db, "users");

    addDoc(ref, newUserInfo);
    console.log(newUserInfo);
    onClose();
  };
  const validateAndLogin = async () => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", email));
    const snap = await getDocs(q);
    // 使用 Firestore 查询邮箱和密码
    try {
      if (isRegistering) {
        setErrorMsg("Validation success, checking your guest account...");
        // const userInfoString = localStorage.getItem("userInfo");
        // const userInfo = JSON.parse(userInfoString);
        // const qt = query(usersRef, where("uid", "==", userInfo.uid));
        // const snapT = await getDocs(qt);
        // if (snapT.empty) {
        //   setErrorMsg(
        //     "Operation failed due to an error, please refresh the page."
        //   );
        //   triggerShake();
        //   return;
        // } else {
        // setErrorMsg(
        //   "Register success, binding your email with current guest account..."
        // );
        // let refId = "";
        // snapT.forEach((e) => {
        //   refId = e.id;
        //   const {
        //     password: userPassword,
        //     email: userEmail,
        //     name: userName,
        //     ...userInfoWithoutPassword
        //   } = e.data();
        //   const snapJ = JSON.stringify({
        //     email: email,
        //     name: email.split("@")[0],
        //     ...userInfoWithoutPassword,
        //   });
        //   localStorage.setItem("userInfo", snapJ);
        // });
        // await updateDoc(doc(db, "users", refId), newUserInfo);
        onClose(); // 关闭登录页面
        // window.location.reload();
        return;
      } else {
        let userFound = false;
        const userDoc = snap.docs[0]; // 获取第一个匹配文档的引用
        const docId = userDoc.id; // 获取文档 ID
        localStorage.setItem("docId", docId);
        snap.forEach((doc) => {
          const { password: userPassword, ...userInfoWithoutPassword } =
            doc.data();
          userFound = true;
          if (!isRegistering) {
            if (userPassword === password) {
              const localGuest = JSON.parse(localStorage.getItem("userInfo"));
              localStorage.setItem("localGuest", JSON.stringify(localGuest));
              localStorage.setItem(
                "userInfo",
                JSON.stringify({
                  email: userInfoWithoutPassword.email,
                  uid: doc.id,
                  ...userInfoWithoutPassword,
                })
              );
              onClose();
              window.location.reload();
              return;
            }
          } else {
            setErrorMsg("This email is already used, please login.");
            triggerShake();
            return;
          }
        });

        if (!userFound && !isRegistering) {
          setErrorMsg("Invalid email or password.");
          triggerShake();
          return;
        }
      }

      // }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setErrorMsg("Operation failed due to an error.");
      triggerShake();
      return;
    }
  };

  return (
    <div className="loginPage" ref={elementRef}>
      <div style={{ height: "100%" }}>
        <button className="closeButton" onClick={onClose}>
          X
        </button>
        <h2 className="loginTitle">Welcome to Reel Freeway</h2>
        <div
          style={{
            height: "100%",
            paddingRight: "auto",
            paddingLeft: "auto",
            width: "100%",
          }}
        >
          {errorMsg !== "" ? <div className="errMsg">{errorMsg}</div> : null}
          {loginState === "email" ? (
            <>
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  paddingRight: "auto",
                  paddingLeft: "auto",
                }}
              >
                <input
                  className="loginInput"
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  className="loginInput"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {isRegistering ? (
                  <input
                    className="loginInput"
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                ) : null}

                {isRegistering ? (
                  <span
                    // className="modal-download-button"
                    className="regBtn"
                  >
                    Already have an account?{" "}
                    <Button
                      type="text"
                      onClick={() => setIsRegistering(false)}
                      className="regBtnText"
                    >
                      Login
                    </Button>
                  </span>
                ) : (
                  <span
                    // className="modal-download-button"
                    className="regBtn"
                  >
                    Do not have an account?
                    <Button
                      type="text"
                      onClick={() => setIsRegistering(true)}
                      className="regBtnText"
                    >
                      Register
                    </Button>
                  </span>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "20px",
                    }}
                  >
                    <button
                      className="modal-download-button"
                      onClick={() => setLoginState("")}
                    >
                      Back
                    </button>
                  </div>
                  {isRegistering ? (
                    <button
                      className="modal-download-button"
                      onClick={handleregister}
                    >
                      Register
                    </button>
                  ) : (
                    <button
                      className="modal-download-button"
                      onClick={validateAndLogin}
                    >
                      Login
                    </button>
                  )}
                </div>
              </div>{" "}
            </>
          ) : null}

          {loginState === "link" ? <div></div> : null}

          {loginState === "" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "5%",
                height: "100%",
              }}
            >
              <button
                className="modal-download-button"
                onClick={() => setLoginState("link")}
              >
                Link Data With App
              </button>
              <button
                className="modal-download-button"
                onClick={() => setLoginState("email")}
              >
                Login With Email
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
