import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Path } from "./RoutePath";
// import LoginNew from "./pages/Authentication/NewAuthentication/login";

export default function CustomRoute(props) {
  //   console.log(props.isAuthenticated);
  const LazyLoadedComponent = lazy(() => import("./pages/dashboard/dashboard"));
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {Path.map((element) => {
          //   console.log(element);

          return (
            <>
              <Route
                exact
                path={element.path}
                element={<element.component />}
              />
              {/* {element.path !== "/portfolio" ? (
                <Route
                  exact
                  path={element.path}
                  element={<element.component />}
                />
              ) : element.path === "/portfolio" && props.isAuthenticated ? (
                <Route
                  exact
                  path={element.path}
                  element={<element.component />}
                />
              ) : (
                <Route exact path={"/portfolio"} element={<LoginNew />} />
              )} */}
            </>
          );
        })}
        <Route
          exact
          path="/dashboard/shop"
          element={<LazyLoadedComponent type="Shop" />}
        />
      </Routes>
    </Suspense>
  );
}
