import React from "react";
import { Layout } from "antd";
import "./publicLayout.css";
import CSHeader from "../Header/header";
import CSFooter from "../Footer/footer";
import { useLocation } from "react-router-dom";

export default function PublicLayout({ children }) {
  const { Content } = Layout;
  const location = useLocation();

  // Check if the current URL starts with "/play"
  const isPlayPage = location.pathname.startsWith("/play");
  return (
    <Layout class="Layout" style={{ height: isPlayPage ? "100vh" : "auto" }}>
      {!isPlayPage && <CSHeader />}
      <Content
        style={{
          height: isPlayPage ? "100vh" : "auto",
          // background: "#FFF",
          minHeight: "100vh",
          // marginBottom: "100px",
          backgroundImage: isPlayPage
            ? `url(${process.env.PUBLIC_URL}/ui/playerBackground.png)`
            : "",
          zIndex: isPlayPage ? 99 : 0,
        }}
      >
        {children}
      </Content>
      {!isPlayPage && <CSFooter />}
    </Layout>
  );
}
